import React  from 'react';
import { Link } from 'react-router-dom';

import { mainNavs } from '../Header/constants';
import Section from '../ContentSection';
import useTranslate from '../../hooks/useTranslate';
import Links from '../Socials/Links';
import Subscribe from '../Subscribe/Subscribe';

import { ReactComponent as Logo } from './img/logo.svg';
import { ReactComponent as Arrow } from '../../assets/simple-arrow.svg';

import './Footer.scss';

const Footer = () => {
  const { t } = useTranslate();

  return (
    <div className="footer">
      <Section className="footerMain">
        <div className="footerInfo">
          <Logo />
          <div className="footerInfoText">Close the Sky Over Ukraine was created by a group of IT professionals and is registered at the FOP Kolomiiets Denys Rostyslavovych(ФОП Коломієць Денис Ростиславович), tax number 3102718054, registration number 145028. </div>
          <Links isHeader={true} className="footerInfoSocials" />
        </div>
        <div className="footerLinks">
          <div className="footerLinksTitle">Links</div>
          <ul>
            {mainNavs.map(link => (
              <li key={link.label}>
                <Link to={link.link}>{t(link.label)}</Link>
              </li>
            ))}
          </ul>
        </div>
        <Subscribe className="footerSub" />
      </Section>
      <Section className="footerPolicies">
        <div className="footerRights">© Skyoverukraine 2022 All rights reserved.</div>
        <div className="footerPoliciesLinks">
          <Link to="/cookies-policy">Cookie Policy</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/terms-and-conditions">Terms and Conditions</Link>
          <Link to="/editorial-board">Editorial board</Link>
          <Link to="/Editorial-Code">Editorial Code</Link>
        </div>
        <div className="footerTopBtn" onClick={() => window.scrollTo(0,0)}>
          <Arrow />
        </div>
      </Section>
    </div>
  );
};

export default Footer;
