import React from 'react';
import Cookies from 'js-cookie';

import Subscribe from '../Subscribe/Subscribe';
import { ReactComponent as IconClose } from '../../assets/close.svg';

import './Cookies.scss';

const CookiesAlert = ({ onClose }) => {

  const handleSuccess = () => {
    Cookies.set('sou-email-subscribed', Date.now(), { expires: 90 });

    setTimeout(() => {
      handleClose();
    }, 500);
  };

  const handleClose = () => {
    Cookies.set('sou-email-subscribed', Date.now(), { expires: 7 });
    onClose();
  };

  return (
    <div className="cookiesAlert">
      <IconClose onClick={() => handleClose()} />
      <Subscribe onSuccess={() => handleSuccess()} />
    </div>
  );
};

export default CookiesAlert;
