export const languages = [
  {
    label: 'De',
    full: 'Deutsch',
    symbol: 'de',
  },
  {
    label: 'En',
    full: 'English',
    symbol: 'en',
  },
  {
    label: 'Es',
    full: 'Español',
    symbol: 'es',
  },
  {
    label: 'Fr',
    full: 'Français',
    symbol: 'fr',
  },
  {
    label: 'It',
    full: 'Italiano',
    symbol: 'it',
  },
  {
    label: 'Pl',
    full: 'Polski',
    symbol: 'pl',
  },
  {
    label: 'Pt',
    full: 'Português',
    symbol: 'pt',
  },
  {
    label: 'Tr',
    full: 'Türkçe',
    symbol: 'tr',
  },
  {
    label: '中文',
    full: '中文 (Zhōngwén)',
    symbol: 'zh',
  },
  {
    label: 'العربية',
    full: 'العربية',
    symbol: 'ar',
  },
  {
    label: 'हिन्दी',
    full: 'हिन्दी',
    symbol: 'hi',
  },
  {
    label: 'עברית',
    full: 'עברית',
    symbol: 'he',
  },
  {
    label: '日本',
    full: '日本語',
    symbol: 'jp',
  },
];
export const availableLanguages = languages.map(l => l.symbol);
export const mainNavs = [
  {
    link: '/latest-news',
    label: 'main.news',
  },
  {
    link: '/fundraising',
    label: 'Fundraising',
  },
  {
    link: '/news',
    label: 'main.articles',
  },
  {
    link: '/help-ukraine',
    label: 'main.help',
  },
  {
    link: '/war-in-ukraine',
    label: 'main.war',
  },
  {
    link: '/about-us',
    label: 'main.about',
  },
];
export const newsCategories = [
  'International politics',
  'World help',
  'Weapons',
  'Russian aggression',
  'NATO',
  'Sanctions',
  'Video',
  'Digest',
];
