import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import Cookies from 'js-cookie';
import loadable from '@loadable/component';

const HeaderInfo = loadable(() => import('./HeaderInfo'));
const HeaderNavigation = loadable(() => import('./HeaderNavigation'));

import Section from '../ContentSection';

const COOKIE_NAME = 'cts_dark_theme';

import './Header.scss';

const Header = () => {
  const [isHideNav, setHideNav] = useState(false);

  useEffect(() => {
    const isDark = Cookies.get(COOKIE_NAME);
    if (isDark) {
      switchTheme(true);
    }
  }, []);

  const switchTheme = (setDark = false) => {
    if (setDark) {
      document.querySelector('body').classList.add('dark-theme');
      return;
    }

    const classList = document.querySelector('body').classList;
    classList.toggle('dark-theme');
    const isDark = classList.contains('dark-theme');
    if (isDark) {
      Cookies.set(COOKIE_NAME, isDark);
    } else {
      Cookies.remove(COOKIE_NAME);
    }
  };

  let scroll = 0;
  const handleScroll = () => {
    const newScroll = window.pageYOffset;
    const delay = 40;
    if (newScroll < 200) {
      setHideNav(false);
      return;
    }
    if ((newScroll - delay) > scroll) {
      setHideNav(true);
      scroll = newScroll;
    } else if ((newScroll + delay) < scroll) {
      setHideNav(false);
      scroll = newScroll;
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="header">
      <Section className={cn({ 'headerInfoHide': isHideNav }, 'headerInfo')}>
        <HeaderInfo handleSwitchTheme={switchTheme} />
      </Section>
      <Section className="headerNavigation">
        <HeaderNavigation handleSwitchTheme={switchTheme} />
      </Section>
    </div>
  );
};

export default Header;
