import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducer';
import createSagaMiddleware, { END } from 'redux-saga';

// const store = createStore(rootReducer);

const configureStore = preloadedState => {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(sagaMiddleware)
  );

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);

  // if (module.hot) {
  //   // Enable Webpack hot module replacement for reducers
  //   module.hot.accept('../reducers', () => {
  //     const nextRootReducer = rootReducer;
  //     store.replaceReducer(nextRootReducer);
  //   });
  // }

  return store;
};

export default configureStore;






// import { createStore, applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';
// import rootReducer from '../reducers';
//
// const configureStore = preloadedState => {
//   const store = createStore(
//     rootReducer,
//     preloadedState,
//     applyMiddleware(thunk)
//   );
//
//   if (module.hot) {
//     // Enable Webpack hot module replacement for reducers
//     module.hot.accept('../reducers', () => {
//       const nextRootReducer = require('../reducers').default;
//       store.replaceReducer(nextRootReducer);
//     });
//   }
//
//   return store;
// };
//
// export default configureStore;

// ????????????????????????????????//

// import { applyMiddleware, createStore, compose } from 'redux';
// import rootReducer from './reducer';
// import { createBrowserHistory, createMemoryHistory } from 'history';
// import { routerMiddleware } from 'connected-react-router';
// import createSagaMiddleware, { END, SagaMiddleware } from 'redux-saga';
//
// export const isServer = !(
//   typeof window !== 'undefined' &&
//   window.document &&
//   window.document.createElement
// );
//
// function getComposeEnhancers() {
//   if (process.env.NODE_ENV !== 'production' && !isServer) {
//     return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//   }
//
//   return compose;
// }
//
// export function configureStore(initialState, url = '/') {
//   const history = isServer
//     ? createMemoryHistory({ initialEntries: [url] })
//     : createBrowserHistory();
//
//   const sagaMiddleware = createSagaMiddleware();
//   const composeEnhancers = getComposeEnhancers();
//   const middlewares = [routerMiddleware(history), sagaMiddleware];
//
//   const store = createStore(rootReducer(history), initialState, composeEnhancers(applyMiddleware(...middlewares)));
//   store.runSaga = sagaMiddleware.run;
//   store.close = () => store.dispatch(END);
//
//   return { store, history };
// }
