export const INITIAL_STATE = {
  newsList: [],
  latestNewsList: [],
  newsCategoryList: {},
  pagesList: [],
  currentNewsPage: null,
  currentStaticPage: null,
  mainPage: null,
  helpPage: null,
};

export const BASE_URL = 'https://gh5y8bvt6h9m.closetheskyoverukraine.com';
const cdnMd = '';
const cdnSm = '';

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SAVE_NEWS':
      return {
        ...state,
        newsList: [...action.news]
          .map(item => {
            const thumbnail = item.thumb ?
              {
                large: cdnMd + item.thumb.replace(/max\/[0-9]*\//, `max/720/`),
                medium: cdnSm + item.thumb.replace(/max\/[0-9]*\//, `max/360/`),
              } : {
                large: `${cdnMd}${BASE_URL}${item.thumbnail.data.attributes.url}?quality=80&embed&format=webp`,
                medium: `${cdnSm}${BASE_URL}${item.thumbnail.data.attributes.url}?quality=60&embed&format=webp`,
              };
            return {...item, thumbnail}
          }),
      };
    case 'SAVE_LATEST_NEWS':
      return {
        ...state,
        latestNewsList: [...action.news].map(item => {
          const link = `${BASE_URL}${item.thumbImg.data.attributes.url}`;
          const thumbnail = {
              large: `${cdnMd}${link}?quality=80&embed&format=webp`,
              medium: `${cdnSm}${link}?quality=60&embed&format=webp`,
            };
          return {...item, thumbnail}
        }),
      };
    case 'SAVE_CATEGORY_NEWS':
      const category = action.news[0]?.category || action.news[0]?.label;
      return {
        ...state,
        newsCategoryList: {
          ...state.newsCategoryList,
          [category]: [...action.news].map(item => {
            const link = `${BASE_URL}${item.thumbImg.data.attributes.url}`;
            const thumbnail = {
              large: `${cdnMd}${link}?quality=80&embed&format=webp`,
              medium: `${cdnSm}${link}?quality=60&embed&format=webp`,
            };
            return {...item, thumbnail}
          }),
        }
      };
    case 'SAVE_CURRENT_NEWS':
      let thumbnail;
      const item = action.page;
      if (item.thumb) {
        thumbnail = {
          large: cdnMd + item.thumb.replace(/max\/[0-9]*\//, `max/720/`),
          medium: cdnSm + item.thumb.replace(/max\/[0-9]*\//, `max/360/`),
        };
      }
      if (item.thumbnail) {
        thumbnail = {
          large: `${cdnMd}${BASE_URL}${item.thumbnail.data.attributes.url}?quality=80&embed&format=webp`,
          medium: `${cdnSm}${BASE_URL}${item.thumbnail.data.attributes.url}?quality=60&embed&format=webp`,
        };
      }
      if (item.thumbImg) {
        const link = `${BASE_URL}${item.thumbImg.data.attributes.url}`;
        thumbnail = {
          large: `${cdnMd}${link}?quality=80&embed&format=webp`,
          medium: `${cdnSm}${link}?quality=60&embed&format=webp`,
        };
      }
      return {
        ...state,
        currentNewsPage: {...action.page, thumbnail},
      };
    case 'SAVE_PAGES':
      return {
        ...state,
        pagesList: action.pages,
      };
    case 'SAVE_STATIC_PAGE':
      return {
        ...state,
        currentStaticPage: action.page,
      };
    case 'SAVE_MAIN':
      return {
        ...state,
        mainPage: action.data,
      };
    case 'SAVE_HELP':
      return {
        ...state,
        helpPage: action.data,
      };
    default: return state;
  }
};

export default reducer;
