import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';
import loadable from '@loadable/component';
import 'swiper/swiper-bundle.min.css';

import { isServer } from './hooks';

//components
import Header from './components/Header';
import Banner from './components/Banner';
import Footer from './components/Footer';
import CookiesAlert from './components/Cookies/Cookies';

//pages
const RenderedPage = loadable(() => import('./pages/RenderedPage/RenderedPage'));
const NewsPage = loadable(() => import('./pages/News/NewsPage'));
const News = loadable(() => import('./pages/News/NewsList'));
const Articles = loadable(() => import('./pages/Articles/Articles'));
const Fundraising = loadable(() => import('./pages/HelpUa/Fundraising'));
const Help = loadable(() => import('./pages/HelpUa'));
const WarInUa = loadable(() => import('./pages/WarInUa/War'));
const Bucha = loadable(() => import('./pages/Bucha/Bucha'));
const Kramatorsk = loadable(() => import('./pages/Kramatorsk/Kramatorsk'));
const NotFound = loadable(() => import('./pages/NotFound/NotFound'));

const Layout = loadable(() => import('./components/Layout'));

import './App.scss';

const App = () => {
  const [isPrompt, setPrompt] = useState(false);

  if (!isServer) {
    document.onreadystatechange = () => {
      const isSubscribed = Cookies.get('sou-email-subscribed');
      if (!isSubscribed && document.readyState === 'complete') {
        setTimeout(() => {
          setPrompt(true);
        }, 3000);
      }
    };
  }

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0,0);
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`https://closetheskyoverukraine.com${location.pathname !== '/' ? location.pathname : ''}`} />
        <meta property="og:url" content={`https://closetheskyoverukraine.com${location.pathname !== '/' ? location.pathname : ''}`} />
        <meta property="twitter:url" content={`https://closetheskyoverukraine.com${location.pathname !== '/' ? location.pathname : ''}`} />
        <meta property="og:title" content="Sky Over Ukraine" />
        <meta name="twitter:title" content="Sky Over Ukraine" />
        <meta property="og:description" content="Here you can find all information regarding military and humanitarian help for the Ukrainian people. Together, we can stop that bloody dictator from conquering Europe" />
        <meta name="twitter:description" content="Here you can find all information regarding military and humanitarian help for the Ukrainian people. Together, we can stop that bloody dictator from conquering Europe" />
        <meta property="og:image" content="https://closetheskyoverukraine.com/preview.jpg" />
        <meta name="twitter:image" content="https://closetheskyoverukraine.com/preview.jpg" />
        <title>Sky Over Ukraine</title>
        {/*{process.env.NODE_ENV === 'development' && <style>{`img {filter: blur(20px) !important;}`}</style>}*/}
      </Helmet>
      <Header />
      <div id="appContent">
        <Switch>
          <Route path="/" component={() => (
            <div>
              <Banner />
              <Layout />
            </div>
          )} exact={true} />
          <Route path="/news" exact={true} component={Articles} />
          <Route path="/latest-news" exact={true} component={News} />
          <Route path="/news/:id" component={NewsPage} />
          <Route path="/latest-news/:id" component={() => <NewsPage type="latest" />} />
          <Route path="/bucha-massacre" component={Bucha} />
          <Route path="/fundraising" component={Fundraising} />
          <Route path="/kramatorsk-massacre" component={Kramatorsk} />
          <Route path="/help-ukraine" component={Help} />
          <Route path="/war-in-ukraine" component={WarInUa} />

          <Route path="/protest" component={() => <Redirect replace to="/join-a-protest-in-your-city" />} />
          <Route path="/spread-information" component={() => <Redirect replace to="/post-in-social-media" />} />
          <Route path="/sign-petitions" component={() => <Redirect replace to="/sign-the-petition" />} />
          <Route path="/donate-to-help" component={() => <Redirect replace to="/donate" />} />

          <Route path="/404" component={NotFound} />

          <Route path="*" component={RenderedPage} />
        </Switch>
      </div>
      {isPrompt && <CookiesAlert onClose={() => setPrompt(false)} />}
      <Footer />
    </>
  );
};

export default App;
