import App from './App';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { loadableReady } from '@loadable/component';

import configureStore from './redux/store';
const store = configureStore(window.__PRELOADED_STATE__);

const BaseApp = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );
}

loadableReady(() => {
  hydrate(
    <BaseApp />,
    document.getElementById('root')
  );
});

if (module.hot) {
  module.hot.accept();
}
