import React from 'react';
import loadable from '@loadable/component';

const Slider = loadable(() => import('./Slider'));

import './Banner.scss';

const Banner = () => {
  return (
    <div className="banner">
      <Slider />
    </div>
  );
};

export default Banner;
