import en from '../public/locales/en/common.json';
import de from '../public/locales/de/common.json';
import fr from '../public/locales/fr/common.json';
import it from '../public/locales/it/common.json';
import pl from '../public/locales/pl/common.json';
import es from '../public/locales/es/common.json';
import zh from '../public/locales/zh/common.json';
import tr from '../public/locales/tr/common.json';
import ar from '../public/locales/ar/common.json';
import hi from '../public/locales/hi/common.json';
import he from '../public/locales/he/common.json';
import jp from '../public/locales/jp/common.json';
import pt from '../public/locales/pt/common.json';

export default {
  en: {
    common: en,
  },
  de: {
    common: de,
  },
  fr: {
    common: fr,
  },
  it: {
    common: it,
  },
  pl: {
    common: pl,
  },
  es: {
    common: es,
  },
  zh: {
    common: zh,
  },
  tr: {
    common: tr,
  },
  ar: {
    common: ar,
  },
  hi: {
    common: hi,
  },
  he: {
    common: he,
  },
  jp: {
    common: jp,
  },
  pt: {
    common: pt,
  },
};
