import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { isServer } from '../../../hooks';

import { ReactComponent as IconTwi } from './img/icons-social-twitter.svg';
import { ReactComponent as IconFb } from './img/icons-social-fb.svg';
import { ReactComponent as IconInst } from './img/icons-social-insta.svg';
import { ReactComponent as IconRt } from './img/icons-social-reddit.svg';
import { ReactComponent as IconTt } from './img/icons-social-tik-tok.svg';
import { ReactComponent as IconTg } from './img/icons-social-tg.svg';
import { ReactComponent as IconLn } from './img/icons-social-linkedin.svg';

import './Links.scss';

const socialLinks = [
  {
    url: 'https://twitter.com/CloseUkraineSky',
    img: IconTwi,
    label: 'Twitter',
  },
  {
    url: 'https://www.facebook.com/Close-The-Sky-Over-Ukraine-101051235880413',
    img: IconFb,
    label: 'Facebook',
  },
  {
    url: 'https://www.instagram.com/close.the.sky.over.ukraine/',
    img: IconInst,
    label: 'Instagram',
  },
  {
    url: 'https://www.reddit.com/r/CloseUkraineSky/',
    img: IconRt,
    label: 'Reddit',
  },
  {
    url: 'https://www.tiktok.com/@close.sky.over.ukraine',
    img: IconTt,
    label: 'TikTok',
  },
  {
    url: 'https://t.me/skyoverukraine',
    img: IconTg,
    label: 'Telegram',
  },
  {
    url: 'https://www.linkedin.com/company/close-the-sky-over-ukraine',
    img: IconLn,
    label: 'LinkedIn',
  },
];

const Links = ({ className }) => {
  return (
    <div className={cn('links', className)}>
      <div className="linksContainer">
        {socialLinks.map(item => {
          const Icon = item.img;
          return (
            <a target="blank" href={item.url} key={item.label} className="linksLink">
              <Icon />
              <span>{item.label}</span>
            </a>
          )
        })}
      </div>
    </div>
  );
};

export default Links;
