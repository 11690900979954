import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import translations from './translations.js';

const availableLanguages = ['en', 'de', 'fr', 'it', 'pl', 'es', 'zh', 'tr', 'ar', 'hi', 'he', 'jp', 'pt'];

// for browser use http backend to load translations and browser lng detector
if (process && !process.release) {
  i18n.use(Backend).use(initReactI18next).use(LanguageDetector);
}

// initialize if not already initialized
if (!i18n.isInitialized) {
  i18n
    .init({
      preload: availableLanguages,
      fallbackLng: 'en', // fallback language is english.
      load: 'languageOnly',
      ns: 'common',
      defaultNS: 'common',
      resources: translations,
      supportedLngs: availableLanguages,
      partialBundledLanguages: true,
      updateMissing: false,
      react: {
        useSuspense: false,
      },

      debug: false,

      interpolation: {
        escapeValue: false, // no need for react. it escapes by default
      },
    });
}

export default i18n;
