import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../i18nextConf';

const useTranslate = () => {
  const { t, ready } = useTranslation('common');

  const translate = (id, variable = null) => {
    if (variable) {
      let res = t(id);
      Object.entries(variable).forEach(([key, value]) => {
        const regex = `%${key}%`;
        res = res.replace(regex, String(value));
      });
      return res;
    }

    return t(id);
  };

  const content = (id, className = '') => {
    const html = t(id);
    return <div dangerouslySetInnerHTML={{__html: html}} className={className}></div>
  };

  return {
    t: ready ? translate : (k) => k,
    i18n,
    content: ready ? content : (k) => k,
  }
};

export default useTranslate;
