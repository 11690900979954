import React, { useState } from 'react';
import cn from 'classnames';

import './Subscribe.scss';

const submitResult = {
  success: {
    message: '',
    className: '--submit-success',
  },
  error: {
    message: 'Please, try again',
    className: '--submit-error',
  },
};

const Subscribe = ({ className, onSuccess }) => {
  const [inputState, setInputState] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!e.target.email.value) return;

    fetch('https://gh5y8bvt6h9m.closetheskyoverukraine.com/api/subscribed-emails', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          email: e.target.email.value,
        },
      }),
    })
      .then(response => response.json())
      .then(data => {
        e.target.reset();
        setInputState(submitResult.success);
        onSuccess();
      })
      .catch(err => setInputState(submitResult.error))
      .finally(() => setTimeout(() => setInputState(null), 2000));
  };

  return (
    <div className={cn('subscribe', className)}>
      <div className="subscribeTitle">Subscribe</div>
      <div className="subscribeInfo">Subscribe to our news and be the first to hear about war in Ukraine</div>
      <form className="subscribeForm" onSubmit={handleSubmit}>
        <input type="email" placeholder="Your email address" name="email" className={cn(inputState?.className)} />
        <button type="submit" className="subscribeFormBtn">Subscribe</button>
      </form>
      <span>{inputState?.message}</span>
    </div>
  );
};

export default Subscribe;
