import React, { useEffect } from 'react';
import cn from 'classnames';

import './Section.scss';

const Section = ({ children, className, id, ...props }) => {
  return (
    <div className={cn('section', className)} {...props}>
      <div className={cn('sectionContent', `${className}Content`)}>
        {children}
      </div>
    </div>
  );
};

export default Section;
