export const getImage = (obj, thumb = false) => {
  if (!obj) return '';
  return (!thumb && obj.medium) || obj.sm || obj.src;
};

export const getFormats = (obj) => {
  if (!obj) return '';

  const xs = obj.xs && `${obj.xs} 300w`;
  const sm = obj.sm && `${obj.sm} 500w`;
  const md = obj.medium && `${obj.medium} 1000w`;
  const lg = obj.large && `${obj.large} 1500w`;
  return [xs, sm, md, lg].filter(i => i).join(', ');
};

export const isServer = !(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);
